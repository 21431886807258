import React from "react"
import { Link } from "gatsby"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import GatsbyImage from "../components/gatsbyImage"

const xsDown = 350
const xsUp = 450

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, shape, transitions }) => ({
    root: {
      width: xsUp,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [breakpoints.down("xs")]: {
        width: xsDown,
      },
      "&:hover #textBackdrop, &:focus #textBackdrop": {
        transform: "scaleX(1)",
      },
    },
    image: {
      width: xsUp,
      height: xsUp,
      [breakpoints.down("xs")]: {
        width: xsDown,
        height: xsDown,
      },
      ...shape,
    },
    label: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      zIndex: 1,
      padding: spacing(3, 5),
      backgroundColor: "transparant",
      ...shape,
      transition: transitions.create("background-color", {
        duration: transitions.duration.complex,
        delay: transitions.duration.shortest,
      }),
      "&:hover #textBackdrop, &:focus #textBackdrop": {
        transform: "scaleX(1)",
      },
      "&:hover ~ #backdrop, &:focus ~ #backdrop": {
        opacity: 0,
      },
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: palette.secondary.light,
      opacity: 0.7,
      transition: transitions.create("opacity", {
        duration: transitions.duration.complex,
      }),
      "&:hover, &:focus": {
        opacity: 0,
      },
      ...shape,
    },
    textBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: palette.secondary.main,
      ...shape,
      zIndex: -1,
      transform: "scaleX(0)",
      transformOrigin: "center",
      transition: transitions.create("transform", {
        duration: transitions.duration.complex,
        delay: transitions.duration.shortest,
      }),
      "&:hover, &:focus": {
        transform: "scaleX(1)",
      },
    },
  })
)

const CategoryButton = ({ data: { image, path, category } }) => {
  const classes = useStyles()

  return (
    <ButtonBase focusRipple className={classes.root} component={Link} to={path}>
      <GatsbyImage imgPath={image} className={classes.image} />
      <Typography
        id="label"
        variant="h4"
        component="h2"
        color="primary"
        align="center"
        className={classes.label}
      >
        {category}
        <span id="textBackdrop" className={classes.textBackdrop} />
      </Typography>
      <span id="backdrop" className={classes.imageBackdrop} />
    </ButtonBase>
  )
}

export default CategoryButton

import { useStaticQuery, graphql } from "gatsby"

const useAllAssets = () => {
  const {
    allFile: { edges: assets },
  } = useStaticQuery(graphql`
    query gallery {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxHeight: 1000, quality: 100) {
                aspectRatio
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  `)

  return assets
}

export default useAllAssets

import React from "react"
import Img from "gatsby-image"
import useFluidImage from "../hooks/useFluidImage"

// ...GatsbyImageSharpFluid_withWebp

const GatsbyImage = props => {
  const fluid = useFluidImage(props.imgPath)

  return <Img fluid={fluid} {...props} />
}

export default GatsbyImage

import useAllAssets from "./useAllAssets"

const useFluidImage = imgKey => {
  const images = useAllAssets()

  const relPath = imgKey.split(/[/ ]+/).pop()
  const [img] = images.filter(img => img.node.relativePath === relPath)

  return img.node.childImageSharp.fluid
}

export default useFluidImage

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryButton from "../components/categoryButton"
import clsx from "clsx"

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  grid: {
    padding: spacing(5, 1),
    width: "100%",
    margin: "0 auto",
    [breakpoints.down("sm")]: {
      padding: spacing(0),
      width: "100%",
    },
  },
  header: {
    paddingTop: spacing(3),
    [breakpoints.up("md")]: {
      paddingTop: spacing(1),
    },
  },
  item: {
    display: "flex",
    justifyContent: "center",
  },
  left: {
    justifyContent: "flex-end",
  },
  right: {
    justifyContent: "flex-start",
  },
  center: {
    justifyContent: "center",
  },
}))

const HomePage = () => {
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    query portfolioQuery {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "portfolio-collection" } } }
      ) {
        nodes {
          frontmatter {
            category
            path
            image
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Layout>
      <SEO title="Portfolio" />
      <Grid container justify="center" spacing={4} className={classes.grid}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            component="h1"
            align="center"
            color="secondary"
            className={classes.header}
          >
            Portfolio
          </Typography>
        </Grid>
        {nodes.map(({ frontmatter: cat }, i) => (
          <Grid
            item
            xs={12}
            lg={6}
            key={cat.path}
            className={clsx(
              classes.item,
              breakpoint
                ? classes.center
                : i % 2 === 0
                ? classes.left
                : classes.right
            )}
          >
            <CategoryButton data={cat} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  )
}

export default HomePage
